<template>
  <div class="news-widget">
    <div class="grid">
      <WidgetHeading class="heading" is-first is-big :content="title" />
    </div>
    <ul class="grid news">
      <li v-for="news in items" :key="news.id" class="item">
        <NewsItem large :news="news" />
      </li>
    </ul>
    <div ref="bottom" />
    <ClientOnly>
      <template #fallback>
        <Pagination :page-count="pageCount" />
      </template>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
defineProps<{ title: string }>()

const bottom = ref<HTMLElement>()

const pageSize = 9
const { items, itemsCount } = useInfiniteScroll('news', bottom, page =>
  useApi().publicPagesNewsList({ page, page_size: pageSize })
)

const pageCount = computed(() =>
  itemsCount.value ? Math.ceil(itemsCount.value / pageSize) : 1
)
</script>

<style lang="scss" scoped>
.heading {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  margin-bottom: rem(16px);
  @include media-up(lg) {
    margin-bottom: rem(24px);
  }
}

.news {
  row-gap: rem(32px);
  @include media-up(md) {
    row-gap: rem(48px);
  }
}

.item {
  @include column(xs, 4);

  @include media-down(lg) {
    @include media-up(md) {
      &:nth-child(even) {
        @include column(md, 4, 4);
      }
    }
  }

  @include media-up(lg) {
    &:nth-child(3n + 2) {
      @include column(lg, 4, 4);
    }

    &:nth-child(3n + 3) {
      @include column(lg, 4, 8);
    }
  }
}
</style>
